import React from "react"

import Layout from "../../components/layout-static"
import Seo from "../../components/seo"

import { Link } from "gatsby"

const Page = () => {
  const metadata = {
    title: "Practice Areas",
    heroH1: "Practice Areas",
    heroH2: "When experience, integrity, and results matter.",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>Practice Areas</h2>
            <p className="mb-8">
              Clients victimized by accidents or abuse deserve fair treatment.
              The attorneys at Bartlett Legal Group, LLC help their clients
              overcome obstacles they face when life-changing legal matters
              arise unexpectedly. We have dedicated Connecticut lawyers who
              provide a high level of personalized and sophisticated legal
              representation.
            </p>
            <h2>Appeals Lawyer CT</h2>
            <p className="mb-8">Appellate experience at our firm provides our clients with quality representation at all appellate levels. Bartlett Legal Group’s appellate attorneys appear in the Connecticut Appellate and Supreme Courts, and United States Courts of Appeals. Their appellate work covers all the firm’s areas of litigation, ranging from personal injury through family law and criminal matters.</p>
            <p className="mb-8"><Link to="/firm-overview/practice-areas/appeals/">Read More...</Link></p>

            <h2>Attorney Case Referral</h2>
            <p className="mb-8">A significant percentage of our cases at Bartlett Legal Group, LLC have been referred to us by other attorneys who are familiar with our results, reputation and experience, both in and out of the courtroom.  I am always happy to speak with attorneys and discuss their personal injury cases to determine if our office can assist…</p>
            <p className="mb-8"><Link to="/firm-overview/practice-areas/attorney-case-referral/">Read More...</Link></p>

            <h2>Connecticut Personal Injury Lawyers</h2>
            <p className="mb-8">While some clients come to our firm suffering pain from physical injuries, others are dealing with emotional trauma from abuse. Regardless of the nature of their injuries we dedicate all of the firm’s resources to not only maximize compensation, but also secure justice on their behalf.</p>
            <p className="mb-8"><Link to="/firm-overview/practice-areas/personal-injury/">Read More...</Link></p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
